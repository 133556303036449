import React from 'react'

const Footer = () => {
  return (
    <div className="footer">
        Developed by Sahil Kumar © 2024 
    </div>
  )
}

export default Footer